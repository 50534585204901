<template>
  <li
    v-if="canViewVerticalNavMenuGroup(item)"
    class="nav-item has-sub"
    :class="{
      'open': isOpen,
      'disabled': item.disabled,
      'sidebar-group-active': isActive,
    }"
    @click="() => updateGroupOpen(!isOpen)"
  >
    <b-link
      v-if="item.route"
      class="d-flex align-items-center"
      :to="{name: item.route}"
    >
      <!-- @click="() => updateGroupOpen(!isOpen)" -->
      <!-- :to="item.title === 'All Clinicians' ? '/apps/all-clinicians' : ''" -->
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-link
      v-else
      class="d-flex align-items-center"
    >
      <!-- @click="() => updateGroupOpen(!isOpen)" -->
      <!-- {{isActive}} -->
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse
      v-model="isOpen"
      class="menu-content"
      tag="ul"
    >
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'

// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
    // outNav: {
    //   type: Boolean,
    //   required: false,
    // }
  },
  data: () => ({
    // isOpenLocal: false,
    itemOpen: '',
  }),
  methods: {
    // needClose() {
    //   setTimeout(() => {
    //     this.updateGroupOpen(false)
    //   }, 1000)
    // },
    // updateGroupOpenLocal() {
    //   this.itemOpen = true
    //   this.updateGroupOpen(true)
    // },
    // updateGroupOpenLocalClose() {
    //   if(!this.itemOpen) {
    //     this.updateGroupOpen(false)
    //   }
    // },
    // updateGroupOpenLocal(val) {
    //   console.log(val)
    //   this.itemOpen = ''
    //   this.itemOpen = val
    //   this.isOpenLocal = true
    // },
  },
  // watch: {
  //   outNav(val) {
  //     console.log(val)
  //     if(val) {
  //       this.updateGroupOpen(false)
  //     }
  //   }
  // },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)

    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuGroup } = useAclUtils()

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
    }
  },
}
</script>

<style>

</style>
