<template>
  <p
    class="clearfix mb-0"
    style="font-size: .8em; color: #aaa; text-align: center; display: grid;"
  >
    <b-link
      href="mailto:jwood@nyproblemgambling.org"
      style="color: #fff; text-align: center; display: grid; font-size: 14px; line-height: 1.42857;"
      class="mb-50"
    >
      Contact us
    </b-link>
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      SEOcloud
      <span class="d-none d-sm-inline-block">, All Rights Reserved</span>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
