export default [
  {
    title: 'My sites',
    icon: 'i-my-sites.svg',
    route: 'sites-no-project',
  },
  {
    title: 'Projects',
    icon: 'i-projects.svg',
    route: 'projects',
  },
  {
    title: 'Licenses',
    icon: 'i-subscription.svg',
    route: 'memberships',
  },
  {
    title: 'Profile',
    icon: 'i-profile.svg',
    route: 'pages-account-setting',
  },
  {
    title: 'Contact',
    icon: 'i-contact.svg',
    route: 'contact',
  },
]
